/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    color: #333;
    line-height: 1.6;
  }
  
  .container {
    min-height: 100vh;
  }
  
  .header {
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .header-buttons {
    display: flex;
    gap: 8px;
  }
  
  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px 16px;
  }
  
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  @media (min-width: 768px) {
    .content-wrapper {
      flex-direction: row;
    }
  }
  
  .sidebar {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: fit-content;
  }
  
  @media (min-width: 768px) {
    .sidebar {
      width: 280px;
      position: sticky;
      top: 80px;
    }
  }
  
  .sidebar-header {
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .sidebar-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .sidebar-content {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .badge {
    background-color: #f0f0f0;
    color: #555;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  
  .main {
    flex: 1;
  }
  
  .results-header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .results-title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .select {
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 8px;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 256px;
  }
  
  .loading-content {
    text-align: center;
  }
  
  .loading-text {
    font-size: 18px;
    margin-bottom: 16px;
    color: #666;
  }
  
  .spinner {
    width: 48px;
    height: 48px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .movie-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 32px;
  }
  
  @media (min-width: 640px) {
    .movie-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .movie-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .movie-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .movie-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .poster-container {
    position: relative;
    background-color: #f0f0f0;
    aspect-ratio: 2 / 3;
  }
  
  .poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .rating-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .movie-content {
    padding: 16px;
  }
  
  .movie-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .movie-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
  }
  
  .meta-item {
    margin-right: 16px;
  }
  
  .genre-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 12px;
  }
  
  .genre-badge {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    color: #555;
  }
  
  .overview {
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .pagination-button {
    padding: 6px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .page-numbers {
    display: flex;
  }
  
  .page-number-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .active-page-button {
    background-color: #3498db;
    color: white;
    border: 1px solid #3498db;
  }
  
  .no-results {
    background-color: white;
    padding: 32px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .no-results-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .no-results-text {
    margin-bottom: 24px;
    color: #666;
  }
  
  .primary-button {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .secondary-button {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .error-container {
    padding: 24px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .error-card {
    background-color: #fff0f0;
    border: 1px solid #ffcccc;
    padding: 24px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
  }
  
  .error-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #cc0000;
  }
  
  .error-message {
    color: #cc0000;
    margin-bottom: 16px;
  }
  
  .button-group {
    display: flex;
    gap: 8px;
  }
  
  @media (max-width: 767px) {
    .sidebar {
      display: none;
    }
  
    .sidebar.show {
      display: block;
    }
  }
  
  