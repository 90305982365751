/* Movie Filter - Clean Minimalist UI - Light Theme */

/* Base styles and reset */
.movie-filter {
  --primary: #3b82f6;
  --primary-hover: #2563eb;
  --bg-light: #ffffff;
  --bg-card: #f8fafc;
  --border-color: rgba(209, 213, 219, 0.5);
  --text-primary: #1e293b;
  --text-secondary: #475569;
  --text-muted: #64748b;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: var(--text-primary);
  line-height: 1.5;
  box-sizing: border-box;
}

.movie-filter *,
.movie-filter *::before,
.movie-filter *::after {
  box-sizing: inherit;
}

/* Main container with background image */
.movie-filter-container {
  min-height: 100vh;
  background: url('./bg.jpg') no-repeat center center; /* Replace with your image path */
  background-size: cover;
  position: relative;
  padding: 2rem 1rem;
}

/* Overlay with blur effect */
.movie-filter-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7); /* White overlay with transparency */
  backdrop-filter: blur(0.005px); /* Light blur - adjust value as needed */
  z-index: 0;
}

.movie-filter-inner {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 1; /* Position content above the blur overlay */
}

/* Header */
.movie-filter-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-primary);
  font-family: 'Roboto', sans-serif; /* Updated font for title */
}


/* Card */
.movie-filter-card {
  background-color: rgba(248, 250, 252, 0.85); /* Slightly transparent card background */
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color);
  padding: 1.5rem;
  margin-bottom: 2rem;
  backdrop-filter: blur(8px);
}

.movie-filter-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

/* Form grid */
.movie-filter-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

@media (min-width: 640px) {
  .movie-filter-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .movie-filter-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Form controls */
.form-group {
  margin-bottom: 0.5rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text-primary);
  background-color: rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
}

.form-select:focus {
  border-color: var(--primary);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

/* Range slider */
.range-container {
  position: relative;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: rgba(203, 213, 225, 0.8);
  outline: none;
  margin: 0.75rem 0;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.range-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.range-slider:focus {
  outline: none;
}

.range-slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.4);
}

.range-marks {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-muted);
}

/* Toggle switch */
.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin-right: 0.75rem;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(203, 213, 225, 0.8);
  transition: .3s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--primary);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

/* Buttons */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .button-container {
    flex-direction: row;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.btn-primary {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);
  flex: 1;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2), 0 2px 4px -1px rgba(59, 130, 246, 0.1);
}

.btn-primary:hover:not(:disabled) {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.btn-secondary {
  color: var(--text-secondary);
  background-color: rgba(241, 245, 249, 0.8);
  border-color: var(--border-color);
}

.btn-secondary:hover:not(:disabled) {
  background-color: rgba(226, 232, 240, 0.8);
}

/* Loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16rem;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  backdrop-filter: blur(5px);
}

.loading-spinner {
  width: 4rem;
  height: 4rem;
  border: 4px solid rgba(59, 130, 246, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 1.125rem;
  color: var(--text-secondary);
  text-align: center;
}

/* Ready state */
.ready-container {
  text-align: center;
  padding: 2rem;
  max-width: 36rem;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(5px);
}

.ready-title {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.ready-subtitle {
  font-size: 0.875rem;
  color: var(--text-muted);
}

/* Error state */
.error-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('./bg.jpg') no-repeat center center; /* Match the main container background */
  background-size: cover;
  position: relative;
  padding: 1rem;
}

.error-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  z-index: 0;
}

.error-card {
  background-color: rgba(254, 226, 226, 0.9);
  backdrop-filter: blur(8px);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(220, 38, 38, 0.2);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.error-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #dc2626;
}

.error-message {
  color: #b91c1c;
  margin-bottom: 1rem;
}

.error-button {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  background-color: rgba(239, 68, 68, 0.1);
  color: #b91c1c;
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
  margin-top: 1rem;
}

.error-button:hover {
  background-color: rgba(239, 68, 68, 0.2);
}
